.HomePage section.section1 h1 {
  position: absolute;
  width: 100%;
  top: 0;
  margin: 7% 0 0 0;
  text-align: center;
  font-family: 'fb', 'helvetica', 'arial';
  color: white;
  font-size: 54px;
  z-index: 10;
}

.HomePage section.HomeProjects {
  margin: 50px 0 0 0;
}

.HomePage section.HomeProjects .MuiContainer-root {
  width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.HomePage section.HomeProjects .MuiContainer-root header.MuiPaper-root {
  box-shadow: none;
  background: transparent;
}

.HomePage
  section.HomeProjects
  .MuiContainer-root
  .react-swipeable-view-container {
  text-align: center;
  border-top: 1px solid #f0f0f0;
  margin-top: 20px;
}

.HomePage
  section.HomeProjects
  .MuiContainer-root
  .react-swipeable-view-container
  .MuiBox-root {
  padding: 20px 0 40px;
}

.HomePage section.HomeProjects .MuiBox-root .items {
  text-align: right;
}

.HomePage .MuiTabs-flexContainer button.MuiButtonBase-root {
  font-size: 18px;
  font-family: 'fb', 'helvetica', 'arial';
  color: grey;
  border-left: 1px solid #c4c4c4;
}

.HomePage .MuiTabs-flexContainer button.MuiButtonBase-root:last-child {
  border-left: none;
}

.HomePage .MuiTabs-flexContainer button.MuiButtonBase-root.Mui-selected,
.HomePage .MuiTabs-flexContainer button.MuiButtonBase-root.Mui-selected:focus,
.HomePage .MuiTabs-flexContainer button:hover,
.HomePage .MuiTabs-flexContainer button.MuiButtonBase-root.Mui-selected:active {
  font-family: 'fb_regular', 'helvetica', 'arial';
  color: black;
}

.HomePage .MuiBox-root .searchCity {
  margin: 0 0 50px 0;
}

.HomePage .MuiBox-root .searchCity h2 {
  font-family: 'fb_regular', 'helvetica', 'arial';
  display: inline-block;
  font-size: 21px;
  vertical-align: middle;
  margin: 20px 0 0 20px;
}

.HomePage .MuiBox-root .searchCity img.searchIcon {
  display: inline-block;
  width: 21px;
  vertical-align: middle;
  margin: 30px 20px 0 0;
}

.HomePage .MuiBox-root .searchCity .MuiFormLabel-root {
  font-family: 'fb_regular', 'helvetica', 'arial';
  direction: rtl;
  text-align: right;
  left: inherit;
  right: 0px;
}

.HomePage .MuiBox-root .searchCity .MuiSelect-select {
  background: transparent;
}

.HomePage .MuiBox-root .searchCity .MuiInput-underline::after {
  border-color: grey !important;
}

.HomePage .MuiBox-root .searchCity .MuiFormLabel-root.MuiFormLabel-filled {
  color: grey;
  right: -25px;
}

.HomePage .MuiBox-root .searchCity .MuiFormLabel-root.Mui-focused {
  color: black;
}

.HomePage .MuiBox-root .searchCity .MuiSvgIcon-root {
  left: 0;
  right: inherit;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard {
  width: 360px;
  margin: 0 0 20px 29px;
  display: inline-block;
  text-decoration: none;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard .text {
  text-align: center;
  padding: 20px 0 0 0;
  min-height: 150px;
}

.HomePage
  section.HomeProjects
  .MuiBox-root
  .items
  .HomeProjectCard:nth-child(3n + 0) {
  margin-left: 0;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard img {
  width: 100%;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard h2 {
  font-family: 'fb', 'helvetica', 'arial';
  font-size: 16px;
  line-height: 1;
  color: #838383;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard h2 small {
  font-size: 16px;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard:hover h2,
.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard:focus h2 {
  color: #5b5b5b;
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-decoration: none;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard button {
  margin: 10px 0 0 0;
  box-shadow: none;
  padding: 5px 20px;
  font-family: 'fb', 'helvetica', 'arial';
  background: #e9e9e9;
}

.HomePage section.HomeProjects .MuiBox-root .HomeProjectCard:hover button {
  background: #9b9b9b;
  color: white;
}

footer.site-footer section.formSec {
  color: white;
  background: center center no-repeat black;
  background-size: cover;
  padding: 70px 0;
  text-align: center;
}

footer.site-footer section.formSec h2 {
  font-size: 23px;
}

footer.site-footer .form-wrapper {
  width: 1100px;
  margin: 0 auto;
  color: white;
}

footer.site-footer .form-wrapper .MuiInputBase-input.MuiInput-input {
  color: white;
}

footer.site-footer .form-wrapper .MuiFormControl-root {
  width: 170px;
  margin-left: 15px;
}

footer.site-footer .form-wrapper .MuiInput-underline::after {
  border-color: white !important;
}

footer.site-footer
  .form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: white;
}

footer.site-footer .form-wrapper .MuiFormHelperText-root {
  color: red;
}

footer.site-footer .form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
  color: grey !important;
}

footer.site-footer .form-wrapper button.sendBtn {
  background: transparent;
  margin: 22px 15px 0px 0;
  vertical-align: top;
}

footer.site-footer .form-wrapper button.sendBtn:hover {
  background: white;
}

footer.site-footer .form-wrapper .selectFormGroup {
  display: inline-block;
  width: 170px;
  margin: 18px 10px 0 10px;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

footer.site-footer .form-wrapper .selectFormGroup select {
  background: 10px center url('../img/icons/downSelect.svg') #21211f no-repeat;
  background-size: 15px;

  color: white;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0;
  padding: 0 0 0 40px;
  appearance: none;
  -webkit-appearance: none;

  font-size: 15px;
}

footer.site-footer .form-wrapper .selectFormGroup span {
  color: red;
  font-size: 11.5px;
  text-align: right;
  display: block;
  padding: 5px 0 0 0;
}

footer.site-footer .MuiInputBase-root.MuiInput-root.MuiInput-underline:before {
  border-color: grey;
}

footer.site-footer
  .MuiInputBase-root.MuiInput-root:hover.MuiInput-underline:before {
  border-block-color: white;
}

footer.site-footer .siteMapTable {
  padding: 30px 0;
  display: table;
  width: 100%;
  text-align: right;
}
footer.site-footer .siteMapTable h2 {
  font-size: 16px;
  margin: 0 0 20px 0;
}

footer.site-footer .siteMapTable .col {
  vertical-align: top;
}

footer.site-footer .siteMapTable .col.sites {
  width: 80%;
}

footer.site-footer .siteMapTable .col.social {
  width: 20%;
  text-align: center;
}

footer.site-footer .siteMapTable .col.sites ul {
  width: 100%;
}

footer.site-footer .siteMapTable .col.sites ul li {
  margin: 0 0 12px 0;
}

footer.site-footer .siteMapTable .col.sites ul li a {
  color: #808080;
  font-size: 12px;
  line-height: 1;
  display: block;
}

footer.site-footer .siteMapTable .col.sites ul li a:hover {
  color: black;
}

footer.site-footer .siteMapTable .tableProjects {
  display: table;
  width: 100%;
}

footer.site-footer .siteMapTable .tableProjects .col {
  width: 15%;
}

footer.site-footer .siteMapTable .tableProjects .col:nth-child(4) {
  width: 30%;
}

footer.site-footer .siteMapTable .tableProjects .col:nth-child(4) ul {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

footer.site-footer .social a {
  display: inline-block;
  margin: 0 7px;
}

footer.site-footer .social a img {
  height: 15px;
  vertical-align: middle;
}

footer.site-footer .social a.whatsapp img {
  height: 20px;
  margin-right: 3px;
}

footer.site-footer .social a:hover img,
footer.site-footer .social a:hover img {
  height: 25px;
}

footer.site-footer img.mainLogo {
  width: 172px;
  display: block;
  margin: 20px auto 20px;
}

footer.site-footer .dansLogos {
  text-align: center;
  margin: 20px 0 0 0;
}

footer.site-footer .bdiLogo {
  margin: 10px 0 0;
  text-align: center;
}

footer.site-footer .bdiLogo img {
  width: 150px;
}


footer.site-footer .dansLogos img {
  width: 130px;
  margin: 0 0 20px 0;
}

/* footer.site-footer .dansLogos img:nth-child(1) {
  width: 130px;
  
} */

.projectView .tableProject {
  display: table;
  width: 100%;
}

.projectView .tableProject .col {
  vertical-align: top;
  padding: 0;
}

.projectView .tableProject .col.navProj {
  width: 30%;
  position: relative;
  background: #e9e9e9;
  padding: 20px 30px;
  z-index: 10;
}

.projectView .tableProject .col.infoProject {
  width: 70%;
}

.projectView .tableProject .navProj header {
  text-align: center;
  width: 390px;
  margin: 0 auto;
}

.projectView .tableProject .navProj header img.logo {
  width: 300px;
}

.projectView .tableProject .navProj header img.logoCity {
  width: 128px;
  margin: 0 0 20px 0;
}

.projectView .tableProject .navProj header h2 {
  font-size: 21px;
  margin: 20px 0 0 0;
}

.projectView .tableProject .navProj header .text {
  width: 390px;
  margin: 20px auto 0;
  font-size: 16px;
  color: #484747;
}

.projectView .tableProject .navProj header .text a {
  color: black;
}

.projectView .tableProject .navProj header .text a:hover,
.projectView .tableProject .navProj header .text a:focus {
  text-decoration: underline;
}

.projectView .tableProject .navProj header .text h3 {
  font-family: 'fb_regular', 'helvetica', 'arial';
  color: black;
  font-size: 18px;
  letter-spacing: 2px;
}

.projectView .tableProject .navProj header ul.navigateElements {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  top: 0;
  margin: 50px 0 50px 0;
}

.projectView .tableProject .navProj header ul.navigateElements li {
  border-bottom: 1px solid grey;
  padding: 20px 0;
  cursor: pointer;
}

.projectView .tableProject .navProj header ul.navigateElements li.toCatalog {
  border: none;
}

.projectView .tableProject .navProj header ul.navigateElements li.toCatalog a {
  background: white;
  font-size: 17px;
  color: black;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #303030;
  margin: 5px 10px 0;
  display: inline-block;
  height: 50px;
  width: 160px;
}

.projectView
  .tableProject
  .navProj
  header
  ul.navigateElements
  li.toCatalog
  a.whatsapp {
  width: 200px;
}

.projectView
  .tableProject
  .navProj
  header
  ul.navigateElements
  li.toCatalog
  a.whatsapp
  img {
  width: 30px;
  margin: -2px 10px 0 0;
}

.projectView
  .tableProject
  .navProj
  header
  ul.navigateElements
  li.toCatalog
  a:hover,
.projectView
  .tableProject
  .navProj
  header
  ul.navigateElements
  li.toCatalog
  a:focus {
  background: black;
  color: white;
}

.projectView .tableProject .navProj .statusProj {
  position: absolute;
  font-size: 16px;
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.projectView .tableProject .navProj header ul.navigateElements li a.active,
.projectView .tableProject .navProj header ul.navigateElements li a:hover {
  font-family: 'fb_regular', 'helvetica', 'arial';
  color: black;
}

.projectView .tableProject .navProj .navigateElements.fixMenu {
  position: fixed;
  top: 80px;
  width: 390px;
}

.projectView .tableProject .infoProject .carouselProj {
  width: 100%;
  min-height: 400px;
}

.projectView .tableProject .infoProject .gallery {
  position: relative;
}
.projectView .tableProject .infoProject .gallery h2.textOnGallery {
  position: absolute;
  font-size: 28px;
  width: 100%;
  bottom: -30px;
  z-index: 20;
  color: white;
  padding: 20px 0;
}

.projectView .tableProject .infoProject .carouselProj .carousel-item img {
  width: 100%;
}

.projectView .tableProject .infoProject section.formSec {
  margin: 30px 0 0 0;
  text-align: center;
  padding: 60px 0 30px;
}

.projectView .tableProject .infoProject section.formSec .sendBtn {
  margin: 22px 0 0px 0;
  vertical-align: top;
}

.projectView .tableProject .infoProject section.formSec h2 {
  font-size: 23px;
  margin: 0;
}

.projectView .tableProject .infoProject section.formSec h2 a {
  color: black;
}

.projectView .tableProject .infoProject section.formSec h2 a:hover,
.projectView .tableProject .infoProject section.formSec h2 a:focus {
  text-decoration: underline;
}

.projectView .tableProject .infoProject .video {
  width: 900px;
  margin: 50px auto;
}

.projectView .tableProject .infoProject h2 {
  text-align: center;
  font-size: 18px;
  margin: 0 0 30px 0;
}

.projectView .tableProject .infoProject section.map {
  margin: 20px 0 50px;
}

.projectView .tableProject .infoProject section.map p.textPlace {
  padding: 0 20%;
  font-size: 14px;
  text-align: center;
  margin: 0 0 40px 0;
}

.projectView .tableProject .infoProject section.map .MapShow {
  width: 900px;
  height: 500px;
  margin: 0 auto;
}

.projectView .tableProject .infoProject section.technics {
  background: center center no-repeat black;
  background-size: cover;
  color: white;
  padding: 50px 0 100px;
  text-align: right;
}

.projectView .tableProject .infoProject section.technics h2 {
  font-size: 21px;
  margin: 0 0 100px 0;
}

.projectView .tableProject .infoProject section.technics h3 {
  font-size: 18px;
}

.projectView .tableProject .infoProject section.technics > div {
  width: 900px;
  margin: 0 auto;
  font-size: 14px;

  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;

  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}

.projectView .tableProject .infoProject section.technics > div ul {
  list-style-type: circle;
}

.projectView .tableProject .infoProject section.plans {
  padding: 80px 0;
  position: relative;
  border-bottom: 1px solid #bcbcbc;
}

.projectView .tableProject .infoProject section.plans h2 {
  font-size: 21px;
}

.projectView .tableProject .infoProject section.plans > div {
  width: 900px;
  margin: 0 auto;
}

.projectView .tableProject .infoProject section.plans .form-group {
  position: absolute;
  top: 230px;
  z-index: 10;
  text-align: right;
  margin: 0 0 30px 0;
}

.projectView .tableProject .infoProject section.plans .form-group label {
  /* display: inline-block; */
  text-align: right;
  margin: 0 0 10px 20px;
  color: grey;
}

.projectView
  .tableProject
  .infoProject
  section.plans
  .form-group
  select.form-control {
  /* display: inline-block; */
  width: 200px;
}

.projectView .infoProject section.plans button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.projectView .infoProject section.plans img.lupa {
  width: 60px !important;
  position: absolute;
  bottom: 110px;
  margin: 0 0 0 15%;
  left: 0;

  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}

.projectView .infoProject section.plans:hover img.lupa {
  opacity: 1;
  filter: alpha(opacity=100); /* For IE8 and earlier */
}

.projectView .tableProject .infoProject section.plans .carousel-inner {
  min-height: 200px;
}

.projectView .tableProject .infoProject section.plans .carousel-item {
  padding: 200px 0 100px 0;
}

.projectView
  .tableProject
  .infoProject
  section.plans
  .carousel-item
  .carousel-caption {
  background: #00000087;
  padding: 10px 0;
  top: 0px;
  bottom: initial;
  width: 100%;
  right: 0;
}

.projectView
  .tableProject
  .infoProject
  section.plans
  .carousel-item
  .carousel-caption
  p {
  color: white;
  padding: 0;
  margin: 0;
}

.projectView .tableProject .infoProject section.plans ol.carousel-indicators {
  top: 80px;
  bottom: initial;
}

.projectView
  .tableProject
  .infoProject
  section.plans
  ol.carousel-indicators
  li {
  background: grey;
  border-width: 0px;
}
.projectView
  .tableProject
  .infoProject
  section.plans
  ol.carousel-indicators
  li.active {
  background: black;
}

.catalogIframe {
  height: 850px;
  width: 100%;
  border: none;
}

.projectView .tableProject .infoProject section.more {
  width: 100%;
  margin: 0px auto 0;

  padding: 50px 10% 0;
}

.projectView .tableProject .infoProject section.more .moreProjectsWrap {
  text-align: center;
}

.projectView .tableProject .infoProject section.more .moreProjectsWrap .item {
  width: 30%;
  vertical-align: top;
  margin: 0 1%;
  display: inline-block;
}

.projectView .infoProject section.more .HomeProjectCard {
  width: 100%;
  margin: 0;
  display: block;
  text-decoration: none;
}

.projectView .infoProject section.more .HomeProjectCard .text {
  text-align: center;
  padding: 20px 0 0 0;
  min-height: 150px;
}

.projectView .infoProject section.more .HomeProjectCard img.mainPic {
  width: 100%;
}

.projectView .infoProject section.more .HomeProjectCard img.logo {
  width: 150px;
  display: block;
  margin: 0 auto;
}

.projectView .infoProject section.more .HomeProjectCard h2 {
  font-family: 'fb', 'helvetica', 'arial';
  font-size: 16px;
  line-height: 1;
  color: #838383;
}

.projectView .infoProject section.more .HomeProjectCard h2 small {
  font-size: 16px;
}

.projectView .infoProject section.more .HomeProjectCard:hover h2,
.projectView .infoProject section.more .HomeProjectCard:focus h2 {
  color: #5b5b5b;
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-decoration: none;
}

.projectView .infoProject section.more .HomeProjectCard button {
  margin: 10px 0 0 0;
  box-shadow: none;
  padding: 5px 20px;
  font-family: 'fb', 'helvetica', 'arial';
}

.projectView .infoProject section.more .HomeProjectCard button span {
  direction: rtl;
  display: inline-block;
}

.projectView .infoProject section.more .HomeProjectCard:hover button,
.projectView .infoProject section.more .HomeProjectCard:focus button {
  background: #9b9b9b;
  color: white;
}

.projectView .infoProject section.more .owl-carousel .owl-nav {
  margin: 170px 0 0 0;
}

.projectView .infoProject section.more h3 {
  text-align: center;
  padding: 20px 0 50px;
  color: grey;
  font-size: 18px;
}

.AllProjectsPage {
  text-align: center;
}

.AllProjectsPage .searchCity {
  margin: 0 0 50px 0;
}

.AllProjectsPage .searchCity h2 {
  font-family: 'fb_regular', 'helvetica', 'arial';
  display: inline-block;
  font-size: 21px;
  vertical-align: middle;
  margin: 20px 0 0 20px;
}

.AllProjectsPage .searchCity img.searchIcon {
  display: inline-block;
  width: 21px;
  vertical-align: middle;
  margin: 30px 20px 0 0;
}

.AllProjectsPage .searchCity img.searchIconMobile {
  display: none;
}

.searchCity img.searchIconMobile {
  display: none;
}

.AllProjectsPage .searchCity .MuiFormLabel-root {
  font-family: 'fb_regular', 'helvetica', 'arial';
  direction: rtl;
  text-align: right;
  left: inherit;
  right: 0px;
}

.AllProjectsPage .searchCity .MuiSelect-select {
  background: transparent;
}

.AllProjectsPage .searchCity .MuiInput-underline::after {
  border-color: grey !important;
}

.AllProjectsPage .searchCity .MuiFormLabel-root.MuiFormLabel-filled {
  color: grey;
  right: -25px;
}

.AllProjectsPage .searchCity .MuiFormLabel-root.Mui-focused {
  color: black;
}

.AllProjectsPage .searchCity .MuiSvgIcon-root {
  left: 0;
  right: inherit;
}

.ContactPage {
  position: relative;
}

.ContactPage .text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgb(255, 255, 255, 0.8);
  padding: 20px 0;
  text-align: center;
}

.ArticlePage .MuiContainer-root {
  padding: 50px 0 50px 0;
}

.ArticlePage h1 {
  text-align: right;
  font-size: 21px;
  margin: 0 0 50px 0;
}

.ArticlePage nav {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}

.ArticlePage nav a {
  display: block;
  color: #909090;
  font-size: 16px;
  text-align: right;
  margin: 0 0 10px 0;
  padding: 0 10px 0 20px;
  position: relative;
  line-height: 1;
}

.ArticlePage nav a:hover,
.ArticlePage nav a:focus {
  color: black;
}

.ArticlePage nav a:before {
  content: '';
  position: absolute;
  background: url('../img/icons/arrowArticle.svg') center center no-repeat;
  background-size: 100%;
  right: 0;
  top: 0;
  margin: 3px -10px 0 0;
  width: 10px;
  height: 10px;
}

.AboutPage {
  padding: 0px 0 0 0;
}

.AboutPage .aboutUp {
  padding: 100px 0 0 0;
  min-height: 745px;
  background: center center no-repeat #e5d9cb;
}

.AboutPage .aboutUp .textConont::before {
  content: '';
  position: absolute;
  margin: -15px -73px 0 0;
  width: 62px;
  height: 53px;
  background: url('../img/icons/geresh.svg') center center transparent no-repeat;
  background-size: cover;
}

.AboutPage .aboutUp .textConont {
  position: relative;
  float: left;
  width: 530px;
  z-index: 10;
}

.AboutPage .aboutUp .textConont h2 {
  font-family: 'FrankRuhlLibreBold', 'helvetica', 'arial' !important;
  font-size: 28px;
  color: #53292f;
  border-bottom: 1px solid #53292f26;
  padding: 0 0 20px 0;
}

.AboutPage .aboutUp .textConont .text {
  font-family: 'fb_regular', 'Roboto', 'helvetica', 'arial';
  font-size: 16px;
}

.AboutPage .aboutUp .textConont .superStrong {
  font-family: 'fb_bold', 'Roboto', 'helvetica', 'arial';
}

.AboutPage .MuiContainer-root {
  position: relative;
  z-index: 10;
  padding: 0 0 100px 0;
}

.AboutPage .allPictures {
  text-align: center;
  padding: 100px 0;
}

.AboutPage .allPictures figure {
  width: 136px;
  vertical-align: top;
  margin: 0 20px;
  display: inline-block;
}

.AboutPage .allPictures figcaption {
  font-size: 18px;
  color: 404041;
  text-align: center;
  line-height: 1.2;
  padding: 20px 0 0 0;
}

.AboutPage .texts {
  font-family: 'fb_regular', 'Roboto', 'helvetica', 'arial';
}

.AboutPage .texts .text {
  color: #58595b;
  font-size: 16px;
  padding: 0 1%;
  width: 48%;
  float: right;
}

.AboutPage .texts .text:nth-child(2) {
  float: left;
}

.AboutPage .texts .text h2 {
  font-size: 21px;
}

.CenterPage header {
  background: #e9e9e9;
  padding: 20px 0;
  text-align: center;
  margin: 0 0 50px 0;
}

.CenterPage header h1 {
  font-size: 24px;
}

.CenterPage nav {
  width: 100%;
  text-align: center;
}

.CenterPage nav a {
  display: inline-block;
  vertical-align: top;
  width: 360px;
  margin: 0 20px 20px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.CenterPage nav a .text {
  text-align: center;
  padding: 20px 0 0 0;
  min-height: 120px;
}

.CenterPage nav a .text h2 {
  font-family: 'fb', 'helvetica', 'arial';
  font-size: 16px;
  line-height: 1;
  color: #838383;
}

.CenterPage nav a .text h2 span {
  font-size: 20px;
}

.CenterPage nav a .text .fakeBtn {
  margin: 10px 0 0 0;
  box-shadow: none;
  padding: 5px 20px;
  font-family: 'fb', 'helvetica', 'arial';
  font-size: 0.875rem;
  background: #e9e9e9;
  display: inline-block;
  border-radius: 4px;
  border: none;
}

.CenterPage nav a .text .fakeBtn:focus {
  background: black;
  color: white;
}

.CenterPage nav a:hover .text h2,
.CenterPage nav a:focus .text h2 {
  color: #5b5b5b;
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-decoration: none;
}

.CenterPage nav a:hover .text .fakeBtn,
.CenterPage nav a:focus .text .fakeBtn {
  background: #9b9b9b;
  color: white;
}

.CenterPage .ShowSelectedProj {
  background: #efefef;
  width: 1160px;
  margin: 0 auto 50px;
  padding: 80px 0;
}

.CenterPage .ShowSelectedProj.notShow {
  background: transparent;
  padding: 0;
}

.CenterPage .ShowSelectedProj figure {
  text-align: center;
  margin: 0 0 70px 0;
}

.CenterPage .ShowSelectedProj figure img {
  width: 136px;
}

.CenterPage .ShowSelectedProj .tableProj {
  display: table;
  width: 80%;
  margin: 0 auto;
}

.CenterPage .ShowSelectedProj .tableProj .col {
  vertical-align: top;
  width: 50%;
}

.CenterPage .ShowSelectedProj .tableProj col.info h2 {
  font-size: 25px;
  margin: 0 0 20px 0;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.JobsPage {
  padding: 0 0 50px 0;
}

.JobsPage header {
  background: #e9e9e9;
  padding: 20px 0;
  text-align: center;
  margin: 0 0 50px 0;
}

.JobsPage header h1 {
  font-size: 24px;
}

.JobsPage iframe {
  margin: 0px auto 0;
  width: 425px;
  height: 110px;
  border: none;
  padding: 0;
  overflow: hidden;
  background: #e9e9e9;
}

.JobsPage .accordion .card {
  width: 800px;
  margin: 0 auto 40px;
  display: block;
  border: none;
}

.JobsPage .accordion .card .card-header {
  padding: 0 0 10px 0;
  background: white;
  border: none;
  border-bottom: 1px solid #9e9fa0;
}

.JobsPage .accordion .card .card-header button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: right;
}

.JobsPage .accordion .card .card-header:hover button,
.JobsPage .accordion .card .card-header:focus button {
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-decoration: underline;
}

.JobsPage .accordion .card .card-header button {
  font-size: 18px;
}

.JobsPage .accordion .card .card-body {
  background: #e9e9e9;
  padding: 0;
}

.JobsPage .accordion .card .card-body > div {
  padding: 20px 50px;
}

.JobsPage .accordion .card .card-header .upDown {
  width: 20px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 20px;

  background: url('../img/icons/down.svg') center center no-repeat;
  background-size: 100%;
}

.JobsPage .accordion .card .card-body ul {
  list-style-type: circle;
  padding: 0 15px 0 0;
  margin: 20px 0;
}

.JobsPage .accordion .card .card-body .DownForm {
  border-top: 1px solid #a9a9aa;
  padding: 30px 50px 0;
}

.JobsPage .accordion .card .card-body .DownForm h3 {
  font-size: 16px;
}

.largMagnify {
  top: 0 !important;
  left: 0 !important;
  width: 100px;
  height: 100px;
  z-index: 20;
}

.PageCalc {
  padding: 50px 0 0 0;
}

.PageCalc h1 {
  text-align: center;
  margin: 0 0 80px 0;
}

.PageCalc .sliderTable {
  display: table;
  width: 60%;
  margin: 0 auto 40px;
}

.PageCalc .sliderTable .col {
  vertical-align: top;
}

.PageCalc .sliderTable .col h2 {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 150px;
}

.PageCalc .sliderTable .col .MuiInput-root {
  width: 100px;
  display: inline-block;
  vertical-align: middle;
}

.PageCalc .sliderTable .col.rightCol {
  width: 45%;
}

.PageCalc .sliderTable .col.leftCol {
  width: 55%;
  padding: 10px 0 0 0;
}

.PageCalc .totalInput {
  text-align: center;
}

.PageCalc .totalInput h2 {
  font-family: 'fb_regular', 'helvetica', 'arial';
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  margin: 0 0 0 20px;
}

.PageCalc .totalInput .MuiInput-underline {
  text-align: right;
  width: 150px;
  display: inline-block;
  vertical-align: bottom;
}

.PageCalc .totalInput .MuiInput-underline::before {
  display: none;
}

.PageCalc .totalInput input.MuiInputBase-input {
  letter-spacing: -1px;
  padding: 0 0 2px 0;
  font-size: 25px;
  font-family: 'fb_regular', 'helvetica', 'arial';
  width: 100%;
  color: #3880ff;
}

.PageCalc .text {
  padding: 40px 20%;
  font-size: 14px;
  text-align: center;
}

.AccPage img.logo {
  width: 100px;
}

.AccPage .columns {
  padding: 50px 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.AccPage .columns .text1 {
  margin: 0 0 20px 0;
}

.AccPage .columns .text2 {
  margin: 20px 0 0 0;
}

.AccPage .columns .text2 a {
  margin: 0 0 10px 0;
}

.AccPage .columns h3 {
  font-size: 18px;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.HomePage .section1 {
  position: relative;
}

.HomePage .section1 .carousel {
  background: black;
}

.HomePage .section1 nav.sec1Buttons {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  margin: 0 0 4% 0;
  text-align: center;
}

.HomePage .section1 nav.sec1Buttons a {
  display: inline-block;
  color: white;
  font-size: 21px;
  border: 1px solid white;
  padding: 10px 20px;
  margin: 0 15px;
  border-radius: 50px;
  width: 229px;
}

.HomePage .section1 nav.sec1Buttons a.whatsapp img {
  width: 25px;
  display: inline-block;
  margin: 0 13px 0 0;
  vertical-align: middle;
}

.HomePage .section1 nav.sec1Buttons a:focus,
.HomePage .section1 nav.sec1Buttons a:hover {
  text-decoration: underline;
}

.HomePage .bulletsSec {
  background: #f2f2f2;
  padding: 80px 0;
}

.HomePage .bulletsSec ul {
  width: 100%;
  text-align: center;
}

.HomePage .bulletsSec ul li {
  display: inline-block;
  vertical-align: top;
  min-width: 130px;
  margin: 0 50px;
  line-height: 1;
  font-size: 18px;
}

.HomePage .bulletsSec ul li strong {
  font-size: 32px;
  letter-spacing: -2px;
  font-weight: normal;
}

.HomePage .bulletsSec ul li:before {
  content: '';
  display: block;
  width: 65px;
  height: 65px;
  margin: 0 auto 10px;
  background: center center transparent no-repeat;
  background-size: 80%;
}

.HomePage .bulletsSec ul li:nth-child(1):before {
  background-image: url('../img/icons/homeBullets/1.svg');
}

.HomePage .bulletsSec ul li:nth-child(2):before {
  background-image: url('../img/icons/homeBullets/2.svg');
}

.HomePage .bulletsSec ul li:nth-child(3):before {
  background-size: 75%;
  background-image: url('../img/icons/homeBullets/3.svg');
}

.HomePage .bulletsSec ul li:nth-child(4):before {
  background-image: url('../img/icons/homeBullets/4.svg');
}

.HomePage section.HomeProjects .HomeProjectsWrap {
  width: 100%;
  padding: 10px 5%;
  text-align: center;
}

nav.navProj .dropdown {
  border: none;
  /* border-left: 1px solid black; */
  border-bottom: 1px solid #cecece;
  padding: 0 0 0px 0;
  height: 49px;
  margin: 0 0 20px 0;
  border-radius: 0;
  display: inline-block;
}

nav.navProj .dropdown .dropdown-toggle {
  border: none;
  padding: 0 0 0 20px;
  border-radius: 0;
  color: black;
}

nav.navProj .dropdown .dropdown-toggle:after {
  margin: 0 10px 0 0;
  font-size: 20px;
  color: grey;
}

nav.navProj .dropdown .dropdown-menu {
  border-radius: 0;
  background: #e0e0e0;
  padding: 0;
}

nav.navProj .dropdown .dropdown-menu a {
  border: none;
  padding: 10px 0px;
  font-size: 16px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid white;
}

nav.navProj .dropdown .dropdown-menu a:last-child {
  border: none;
}

nav.navProj .dropdown .dropdown-menu a.active,
nav.navProj .dropdown .dropdown-menu a:hover,
nav.navProj .dropdown .dropdown-menu a:focus {
  color: black;
  background: none;
  text-decoration: underline;
}

nav.navProj .citiesNav {
  display: inline-block;
  margin: 0 0 0px 0;
}

/* nav.navProj .citiesNav button:nth-child(1) {
    font-size: 21px;
} */

nav.navProj .citiesNav button,
nav.navProj .citiesNav span {
  line-height: 1;
  font-size: 16px;
  border: none;
  margin: 0 0 20px 0;
  padding: 10px 10px 23px;
  height: 53px;
  display: inline-block;
  border-bottom: 1px solid #cecece;
}

/* nav.navProj .citiesNav button.active,
nav.navProj .citiesNav button:hover,
nav.navProj .citiesNav button:focus
{
    border-bottom: 2px solid #bd1823;
} */

nav.navProj .citiesNavMobile,
nav.navProj .Mobilelupa {
  display: none;
}

nav.navProj span.divider {
  border-bottom: 1px solid #cecece;
  padding: 0 0px 18px 5px;
  list-style: 0.8;
  display: inline-block;
  font-size: 26px;
}

.AllProjectsPage .allCards {
  padding: 20px 0 0 0;
  text-align: center;
}

.AllProjectsPage .HomeProjectCard {
  width: 580px;
  margin: 0 0px 50px;
  padding: 0 20px;
  border-bottom: 1px solid #cecece;
  display: inline-block;
  text-decoration: none;
}

.AllProjectsPage .HomeProjectCard .text {
  text-align: center;
  padding: 20px 0 0 0;
  min-height: 130px;
}

.AllProjectsPage .items {
  text-align: center;
}

.AllProjectsPage .HomeProjectCard img.mainPic {
  width: 100%;
}

.AllProjectsPage .HomeProjectCard img.logo {
  height: 90px;
  margin: 0 0 10px 0;
}

.AllProjectsPage .HomeProjectCard h2 {
  font-family: 'fb', 'helvetica', 'arial';
  font-size: 16px;
  line-height: 1;
  color: #989898;
  float: right;
}

.AllProjectsPage .HomeProjectCard h2 strong {
  color: #404041;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 0 0 0 5px;
}

.AllProjectsPage .HomeProjectCard h2 small {
  font-size: 16px;
}

.AllProjectsPage .HomeProjectCard:hover h2,
.AllProjectsPage .HomeProjectCard:focus h2 {
  color: #5b5b5b;
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-decoration: none;
}

.AllProjectsPage .HomeProjectCard button {
  margin: 0px 0 0 0;
  box-shadow: none;
  padding: 5px 20px;
  font-family: 'fb', 'helvetica', 'arial';
  background: #e9e9e9;
  float: left;
}

.AllProjectsPage .HomeProjectCard:hover button {
  background: #9b9b9b;
  color: white;
}

.AllProjectsPage nav {
  background: transparent;
  padding: 10px 0;
  margin: 0 0 20px 0;
}
.AllProjectsPage nav button {
  font-size: 21px;
  background: none;
  border: none;
  color: #b9b9b9;
  padding: 0 20px;
  border-left: 1px solid grey;
  /* cursor: pointer; */
  cursor: default;
}
/* .AllProjectsPage nav button.active,
.AllProjectsPage nav button:hover,
.AllProjectsPage nav button:focus
{
    color: #231F20;
} */
.AllProjectsPage nav button:last-child {
  border-left: none;
}

.body-container.welcome_projects .site-header {
  border: none;
}

.Projects .allCards {
  padding: 50px 10%;
}

.Projects .navProj {
  margin: 20px 0 20px 0;
  background: #f7f5f5;

  padding: 10px 0 0 0;
}

.Projects nav.navProj .dropdown,
.Projects nav.navProj .citiesNav button,
.Projects nav.navProj span.divider {
  border: none;
}

.Projects nav.navProj .citiesNav button.active,
.Projects nav.navProj .citiesNav button:hover,
.Projects nav.navProj .citiesNav button:focus {
  border-bottom: 2px solid #bd1823;
}

.projectView .bullets_advantage {
  padding: 20px 0 50px;
  border-bottom: 1px solid #bcbcbc;
  margin: 0 0 50px 0;
}
.projectView .bullets_advantage ul {
  margin: 50px 0 0 0;
  width: 100%;
  text-align: center;
}

.projectView .bullets_advantage ul li {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-size: 16px;
  width: 150px;
  margin: 0 10px;
}

.projectView .bullets_advantage ul li:before {
  content: '';
  display: block;
  margin: 0 auto 20px;
  width: 80px;
  height: 50px;
  background: center center transparent no-repeat;
  background-size: 50%;
}

.projectView.projId32 .bullets_advantage ul li:nth-child(1)::before {
  background-image: url('../img/icons/projectsIcons/projId32/1.svg');
}
.projectView.projId32 .bullets_advantage ul li:nth-child(2)::before {
  background-image: url('../img/icons/projectsIcons/projId32/2.svg');
}
.projectView.projId32 .bullets_advantage ul li:nth-child(3)::before {
  background-image: url('../img/icons/projectsIcons/projId32/3.svg');
}
.projectView.projId32 .bullets_advantage ul li:nth-child(4)::before {
  background-image: url('../img/icons/projectsIcons/projId32/4.svg');
}
.projectView.projId32 .bullets_advantage ul li:nth-child(5)::before {
  background-image: url('../img/icons/projectsIcons/projId32/5.svg');
  background-size: 80%;
}
.projectView.projId32 .bullets_advantage ul li:nth-child(6)::before {
  background-image: url('../img/icons/projectsIcons/projId32/6.svg');
  background-size: 80%;
}

a.download {
  display: block;
  position: absolute;
  background: black;
  width: 60px;
  height: 60px;
  padding: 12px 0 0 0;
  bottom: 110px;
  left: 0;
  margin: 0 0 0 23%;

  text-align: center;
  border-radius: 50%;
}

a.download img {
  display: inline-block;
  width: 31px !important;
}

a.download:hover img,
a.download:focus img {
  width: 33px !important;
}

.CenterPage .ShowSelectedProj .tableProj .col.info ul {
  list-style-type: disc;
  margin: 0 20px 0 0;
}

.ShowPopUp {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  /* min-height: 100px; */
  z-index: 20;
  background: #686868f0;
  border: 1px solid black;
  padding: 0 0 10px 0;
  border-radius: 5px;
}

.ShowPopUp .headerPopUp button {
  color: white;
  background: none;
  border: none;
  font-size: 14px;
}

.ShowPopUp .headerPopUp button:hover,
.ShowPopUp .headerPopUp button:focus {
  color: red;
}

.ShowPopUp .textPopUp {
  color: white;
  text-align: center;
  padding: 0 20px;
}

.ShowPopUp .textPopUp p {
  margin: 0 0 5px 0;
  line-height: 1.2;
}

.ShowPopUp .link {
  margin: 10px 0 0 0;
  text-align: center;
}

.ShowPopUp .link a {
  display: inline-block;
  padding: 5px 16px;
  border-radius: 10px;
  background: #5c5c5c;
  color: white;
  font-size: 15px;
}

.ShowPopUp .link a:hover,
.ShowPopUp .link a:focus {
  background: black;
}

.urbanRenewalPage.projectView .tableProject .col.navProj {
  position: fixed;
  min-width: 448px;
  padding: 100px 0 0 0;
  top: 0;
  bottom: 0;
}

.urbanRenewalPage header {
  padding: 20px 0 0 0;
}

.urbanRenewalPage .tableProject header h1 {
  font-family: 'fb_bold', 'helvetica', 'arial';
  font-size: 34px;
  letter-spacing: -1px;
}

.urbanRenewalPage .tableProject header hr {
  border-color: #adadad;
  width: 312px;
}

.urbanRenewalPage .tableProject .navProj header .text {
  width: 352px;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.urbanRenewalPage header .text .downMouse {
  width: 25px;
  margin: 20px auto;
  display: inline-block;
}

.urbanRenewalPage .urbanProjects {
  padding: 30px 0 0 0;
  min-width: 970px;
  text-align: center;
}

.urbanRenewalPage .urbanProjects .urbanProject {
  width: 80%;
  max-width: 1200px;
  min-height: 148px;
  margin: 0 auto 60px;
  position: relative;


  box-shadow: 0px 0px 13px 5px rgb(0 0 0 / 10%);
}

.urbanRenewalPage .urbanProjects .urbanProject .gallery .picturesGal {
  background: center center no-repeat transparent;
  background-size: cover;
  width: 100%;
  height: 530px;
  margin: -1px 0 0 0;
}

.urbanRenewalPage .urbanProjects .urbanProject .down {
  position: relative;
  background: white;
  min-height: 144px;
}

.urbanRenewalPage .urbanProjects .urbanProject .down .flach {
  position: absolute;
  top: 0;
  right: 0;
  margin: -36px -28px 0 0;
}

.urbanRenewalPage .urbanProjects .urbanProject .down .flach h2 {
  background: #bd1823;
  padding: 17px 30px 21px;
  font-family: 'fb_regular', 'helvetica', 'arial';
  font-size: 27px;
  color: white;
  text-align: right;
  line-height: 1;
  margin: 0;
}

.urbanRenewalPage .urbanProjects .urbanProject .down .flach .triangle {
  display: block;
  width: 28px;
  height: 24px;
}

.urbanRenewalPage .urbanProjects .urbanProject .down .logos {
  margin: 30px 0 0 30px;
  float: left;
}

.urbanRenewalPage .urbanProjects .urbanProject .down .text {
  font-family: 'fb_regular', 'helvetica', 'arial';
  font-size: 15px;
  padding: 60px 30px 30px 20px;
  float: right;
  text-align: right;
  max-width: 360px;
}

.urbanRenewalPage .urbanProjects .urbanProject.donthave_logos .down .logos {
  display: none;
}

.urbanRenewalPage .urbanProjects .urbanProject.donthave_logos .down .text {
  float: none;
  max-width: 80%;

  p {
    margin: 0;
  }
  
}

.urbanRenewalPage .urbanProjects .urbanProject.no_pic {
  /* width: 36%; */
  display: inline-block;
  vertical-align: top;
  margin: 30px 4% 60px;
}

.urbanRenewalPage .urbanProjects .urbanProject.no_pic .down .text {
  float: initial;
}

.avisrorTxt {
  margin: 10px 0 0 0; 
  font-size: 14px;
  font-family: 'fb_regular', 'Roboto', 'helvetica', 'arial';
}

.welcome_urbanRenewal {

  
  
  .tableProject .col.navProj {
    background: #F6F6F6;
  }

}


.showWhastapp {

  position: fixed;
  bottom: 30px;
  left: 20px;

  width: 65px;
  z-index: 20;

  img {
    width: 100%;
  }

}

.mobile .showWhastapp {
  bottom: 50px;
}