@media all and (max-width:1150px) {
   /*  body .site-header .social {
        display: none;
    } */

    .desktop .site-header .main-menu img.mainLogo {
        height: 20px;
    }

    footer.site-footer .form-wrapper {
        width: 100%;
    }

    .projectView .tableProject .navProj .navigateElements.fixMenu {
        position: relative;
    }
}


@media all and (max-width:1300px) {

    /* FOR ZOOM 150% */

    .desktop .projectView .tableProject .infoProject section.technics > div {

        column-count: 1;
        margin: 0 auto;
        width: 580px;
        display: block;
    }

}
