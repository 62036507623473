@font-face {
  font-family: 'fb';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('../fonts/OpenSansHebrew-Light.woff') format('opentype'); /* here you go, IE */
}

@font-face {
  font-family: 'fb_regular';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('../fonts/OpenSansHebrew-Regular.woff') format('opentype'); /* here you go, IE */
}

@font-face {
  font-family: 'fb_bold';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('../fonts/OpenSansHebrew-Bold.woff') format('opentype'); /* here you go, IE */
}

@font-face {
  font-family: 'FrankRuhlLibreBold';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  src: url('../fonts/FrankRuhlLibre-Bold.woff') format('opentype'); /* here you go, IE */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff)
      format('woff');
}

body {
  direction: rtl !important;
  text-align: right !important;
  font-family: 'fb', 'helvetica', 'arial' !important;
  padding: 0px 0 0px 0;
  margin: 0;
  font-size: 18px !important;
  color: black;
  background-color: rgba(255, 255, 255, 0.95);
  /* min-width: 1200px; */
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.body-container {
  padding: 90px 0 0 0;
}

.body-container.welcome_index,
.body-container.welcome_en {
  padding: 0px 0 0 0;
}

.hidden {
  display: none;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p {
  direction: rtl;
}

strong {
  font-family: 'fb', 'Roboto', 'helvetica', 'arial';
  font-weight: bold;
}

.wrapper {
  display: block;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.wrapper:before {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

.wrapper:after {
  content: '';
  display: block;
  clear: both;
}

.clear:before {
  content: '';
  display: block;
  clear: both;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}

header,
section,
footer,
aside,
nav,
article,
figure,
figcaption,
hgroup {
  display: block;
}

img,
img a {
  border: none;
}

.col {
  display: table-cell;
}

.fullWidth {
  width: 100%;
}

.header-wrapper {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 999;
}

/**************************** site-header ***************************************/

.site-header {
  display: block;
  width: 100%;
  display: table;
  text-align: center;
  position: fixed;
  top: 0;
  margin: 0;
  border-bottom: 1px solid #ececec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 20px 20px;
  background: white;
}

.site-header .col {
  height: 0px;
}

.site-header .main-menu {
  padding: 0 8% 0 0;
  text-align: right;
}

.site-header .main-menu .colTable {
  display: table-cell;
}

.site-header .main-menu img.mainLogo {
  width: 100px;
  height: 51px;
  margin: 0 0 0 30px;
}

.welcome_index .site-header .main-menu img.mainLogo,
.welcome_en .site-header .main-menu img.mainLogo {
  width: 133px;
}

.site-header .main-menu nav {
  text-align: right;
  display: inline-block;
}

.site-header .main-menu nav a {
  display: inline-block;
  margin: 0 10px;
  color: black;
}

.body-container.welcome_index .site-header,
.body-container.welcome_en .site-header {
  background: transparent;
  border: none;
}

body .body-container.welcome_index .site-header .main-menu nav a,
body .body-container.welcome_index .site-header .social .leftMenu a {
  color: white;
}

body.header-scroll .body-container .site-header {
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #ececec;
}

body
  .body-container.welcome_index
  .site-header
  .main-menu
  nav
  .dropdown
  button.dropdown-toggle {
  color: white;
}

body .site-header .main-menu nav .dropdown button.dropdown-toggle {
  background: transparent;
  font-size: 18px;
}

body.header-scroll .body-container.welcome_index .site-header .main-menu nav a,
body.header-scroll
  .body-container.welcome_index
  .site-header
  .main-menu
  nav
  .dropdown
  button.dropdown-toggle,
body.header-scroll
  .body-container.welcome_index
  .site-header
  .social
  .leftMenu
  a {
  color: black;
  font-size: 18px;
}

body .site-header .main-menu nav .dropdown {
  display: inline-block;
}

body .site-header .main-menu nav .dropdown button.dropdown-toggle {
  padding: 0 5px 3px 0;
}

body .site-header .main-menu nav .dropdown button.dropdown-toggle:after {
  margin-right: 10px;
}

body .site-header .main-menu nav .dropdown .dropdown-menu {
  background: #e0e0e0;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  min-width: initial;
}

body .site-header .main-menu nav .dropdown .dropdown-menu a,
body .site-header .main-menu nav .dropdown .dropdown-menu button {
  width: 100%;
  margin: 0;
  text-align: center;
  color: black;
  background: transparent;
  border: none;
  padding: 15px 0;
  border-bottom: 1px solid white;
}

body .site-header .main-menu nav .dropdown .dropdown-menu a:last-child {
  border: none;
}

body .site-header .main-menu nav .dropdown .dropdown-menu a:hover,
body .site-header .main-menu nav .dropdown .dropdown-menu a:focus {
  text-decoration: underline;
}

body .site-header .main-menu nav .dropdown button.dropdown-toggle:focus,
body .site-header .main-menu nav .dropdown button.dropdown-toggle:hover {
  text-decoration: underline;
}

body .site-header .main-menu nav a.is-active,
body .site-header .main-menu nav a:focus,
body .site-header .main-menu nav a:hover,
body .site-header .social .leftMenu a.is-active {
  font-family: 'fb_regular', 'helvetica', 'arial';
  color: black !important;
}

body .site-header .main-menu a.home img {
  width: 26px;
  height: 20px;
  vertical-align: middle;
  margin: -2px 0 0 15px;
}

body .body-container.welcome_index .site-header .main-menu a.home img.white {
  display: inline-block;
}

body.header-scroll .body-container .site-header .main-menu a.home img.white,
body .body-container .site-header .main-menu a.home img.white {
  display: none;
}

body .body-container.welcome_index .site-header .main-menu a.home img.black {
  display: none;
}

body.header-scroll .body-container .site-header .main-menu a.home img.black {
  vertical-align: middle;
  display: inline-block;
}

body .site-header .social {
  text-align: left;
  min-width: 350px;
}
body .site-header .social a {
  display: inline-block;
  margin: 0 5px;
}
body .site-header .social a img {
  height: 16px;
  vertical-align: middle;
}

body .site-header .social a.whatsapp img {
  height: 19px;
}

body .site-header .social .leftMenu {
  display: inline-block;
  border-left: 1px solid grey;
  padding: 0 0 0 10px;
  margin-left: 10px;
}

body .site-header .social .leftMenu a {
  padding: 5px 0px;
  color: black;
}

body .site-header .social .leftMenu a img {
  margin: 0 10px 0 0;
}

body .body-container.welcome_index .site-header .social a img.white {
  vertical-align: middle;
  display: inline-block;
}

body.header-scroll .body-container .site-header .social a img.white,
body .body-container .site-header .social a img.white {
  display: none;
}

body .body-container.welcome_index .site-header .social a img.black {
  display: none;
}

body.header-scroll .body-container .site-header .social a img.black {
  vertical-align: middle;
  display: inline-block;
}

body .site-header .social span.sitePhone {
  font-size: 20px;
  color: black;
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px 0px 0;
}

body .body-container.welcome_index .site-header .social span.sitePhone {
  color: white;
}

body.header-scroll
  .body-container.welcome_index
  .site-header
  .social
  span.sitePhone {
  color: black;
}

body .body-container .site-header .social a:hover img,
body .body-container .site-header .social a:hover img,
body .body-container .site-header .social a:focus img,
body .body-container .site-header .social a:focus img {
  height: 25px;
}

/**************************** END site-header ***************************************/

/**************************** CONTENT ***************************************/

.content-wrapper {
  min-height: 575px;
  margin: auto;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/**************************** FOOTER ***************************************/

.footer-wrapper {
  position: relative;
  z-index: 10;
}
.footer-wrapper:before {
  content: '';
  width: 100%;
  height: 45px;
}

.credit {
  margin: 0px 0 0 0;
  padding: 0px 0 20px;
  text-align: center;
}
.site-footer {
  padding: 0px 0;
  margin: 0px 0 0 0;
  color: black;
  position: relative;
  background: white;
}

::-webkit-input-placeholder {
  padding-top: 0px;
  color: rgba(255, 255, 255, 0) !important;
  font-size: 80%;
}
:-moz-placeholder {
  padding-top: 0px;
  color: rgba(255, 255, 255, 0) !important;
  font-size: 80%;
}
::-moz-placeholder {
  padding-top: 0px;
  color: rgba(255, 255, 255, 0) !important;
  font-size: 80%;
}
:-ms-input-placeholder {
  padding-top: 0px;
  color: rgba(255, 255, 255, 0) !important;
  font-size: 80%;
}

.form-wrapper .MuiFormControl-root {
  width: 205px;
}

.form-wrapper .makeStyles-container-1 {
  display: inline-block;
}

.form-wrapper .MuiInputBase-input.MuiInput-input {
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.form-wrapper .MuiFormLabel-root {
  right: 0;
}

.form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-family: 'fb_regular', 'helvetica', 'arial';
  text-align: right;
}

.form-wrapper .MuiFormLabel-root.Mui-focused,
.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
  right: -65px !important;
}

.form-wrapper > div {
  display: inline-block;
}

.form-wrapper .MuiFormHelperText-root {
  text-align: right;
}

.form-wrapper > div > span {
  display: block;
  color: red;
  padding: 20px 0;
}

.form-wrapper button.sendBtn {
  background: #353535;
  vertical-align: bottom;
  margin: 0px 15px 30px 0;
  padding: 5px 30px;
}

.form-wrapper button.sendBtn:hover,
.form-wrapper button.sendBtn:focus {
  background: white;
  border: 1px solid black;
  color: black;
}

.form-wrapper button.sendBtn.btn-danger {
  background: #a00;
}

.form-wrapper .MuiInput-underline::after {
  border-color: black !important;
}

.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
  color: grey !important;
}

.form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: grey;
}

.modal {
  z-index: 999;
  margin-top: 100px;
}
.modal .modal-dialog .modal-header {
  text-align: center;
  color: black;
}

.modal .modal-dialog .modal-header h5 {
  width: 100%;
  font-size: 30px;
}

.modal .modal-dialog .modal-body {
  padding: 40px 0;
}

.modal .modal-dialog .modal-header button {
  float: left;
  left: 0;
  margin: 0;
  padding: 0;
}

.modal .modal-dialog .modal-body {
  color: black;
}

.modal.error-modal .modal-dialog .modal-header {
  background: red;
}
.modal.error-modal .modal-dialog .modal-header h5 {
  color: white;
}

.modal.error-modal .modal-dialog .modal-footer button {
  background: red;
  color: white;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.carouselProj .carousel-control-next,
.carouselProj .carousel-control-prev {
  width: 10%;
  opacity: 1;
}

.carouselProj .carousel-control-next span {
  background: url('../img/icons/white/rightBold.svg') center center no-repeat;
  background-size: 100%;
  width: 30px;
  height: 65px;
}

.carouselProj .carousel-control-prev span {
  background: url('../img/icons/white/leftBold.svg') center center no-repeat;
  background-size: 100%;
  width: 30px;
  height: 65px;
}

section.plans .carouselProj .carousel-control-next span {
  background-image: url('../img/icons/black/arrowLbold.svg?v=1');
}
section.plans .carouselProj .carousel-control-prev span {
  background-image: url('../img/icons/black/arrowRbold.svg?v=1');
}

section.plans .carouselProj .carousel-control-next {
  right: initial;
  left: 0;
  opacity: 0.8;
}
section.plans .carouselProj .carousel-control-prev {
  left: initial;
  right: 0;
  opacity: 0.8;
}

section.gallery .carouselProj ol.carousel-indicators {
  bottom: -50px;
}

section.gallery .carouselProj ol.carousel-indicators li {
  width: 10px;
  height: 10px;
  background: white;
  border: 1px solid grey;
  border-radius: 50%;
}

section.gallery .carouselProj ol.carousel-indicators li.active {
  background: grey;
}

.owl-carousel .owl-nav {
  top: 0;
  position: absolute;
  width: 100%;
  margin: 15% 0 0 0;
}

.owl-carousel .owl-nav span {
  display: none;
}

.owl-carousel .owl-nav button.owl-prev {
  background: url('../img/icons/black/leftGrey.svg') center center no-repeat;
  background-size: 100%;
  width: 30px;
  height: 60px;
  left: -50px;
  position: absolute;
}
.owl-carousel .owl-nav button.owl-next {
  background: url('../img/icons/black/rightGrey.svg') center center no-repeat;
  background-size: 100%;
  width: 30px;
  height: 60px;
  position: absolute;
  right: -50px;
}

.credit {
  background: white;
  text-align: center;
}

.credit img {
  width: 150px;
}

.modal.BigPictures .modal-dialog button.close {
  position: absolute;
  background: black;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 20px;
  color: white;
}

.modal.BigPictures .modal-dialog {
  width: 100%;
  max-width: 95%;
  padding: 10px;
  margin: 0 auto;
}

.modal.BigPictures .modal-dialog .modal-body {
  padding: 0;
}

.modal.BigPictures .modal-dialog .modal-body img {
  width: 100%;
  max-width: 100%;
}

.modal.BigPictures .modal-dialog .modal-body p {
  text-align: center;
  background: #00000087;
  color: white;
  padding: 10px 0;
  font-size: 20px;
}

.Page404 {
  text-align: center;
  padding: 80px 0;
}

.Page404 img {
  width: 400px;
}

.Page404 a:hover img,
.Page404 a:focus img {
  width: 410px;
}

h3.formMustText {
  font-size: 16px;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}



@keyframes bounce1 {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  60% {
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.bounce1 {
  -moz-animation: bounce1 2s infinite;
  -webkit-animation: bounce1 2s infinite;
  animation: bounce1 2s infinite;
}



