.body-container.welcome_index {
    padding: 0 !important;
}

.body-container.mobile {
    padding: 58px 0 0 0;
}

.mobile .content-wrapper {
    min-height: initial;
}

.mobile .site-header {
    padding: 10px 10px;
}


.mobile  .site-header .main-menu {
    width: 70%;
    padding: 0;
}

.mobile  .site-header .main-menu a {
    display: inline-block;
    width: 80px;
    margin: 0;
    vertical-align: middle;
    
}

.mobile  .site-header .main-menu img.mainLogo {
    width: 80px;
    height: 41px;
    margin: 0;
}


.mobile .site-header button.MuiButton-root {
    outline: none;
}

.mobile .site-header .openIcon {
    width: 30px;
    margin: 4px 0 0 0;
}

.mobile .site-header .col.open {
    text-align: right;
    padding: 0;
}


.mobile .site-header .openIcon span {
    width: 100%;
    height: 2px;
    margin: 0 0 5px 0;
    display: block;
}

.mobile.welcome_index .site-header .openIcon span {
    background: white;
}


.header-scroll .mobile .site-header .openIcon span,
.mobile .site-header .openIcon span
{
    background: #888A8B;
}



.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root {
    padding: 30px 0;
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li {
    text-align: center;
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li a {
        color: grey;
        text-align: center;
        width: 100%;   
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li a.is-active {
    font-family: "fb_regular","helvetica","arial";
    color: black;
    
}




.mobileDraw.menuRight .social {
    text-align: center;
    width: 100%;
    padding: 0px 0;
}

.mobileDraw.menuRight .social a {
    display: inline-block;
    margin: 0 8px;
}

.mobileDraw.menuRight .social a img {
    height: 25px;
    vertical-align: middle;
    
}

.mobileDraw.menuRight .social a.whatsapp img {
    height: 30px;
}


.mobileDraw.menuRight .social span.sitePhone {
    font-size: 20px;
    color: black;
    display: block;
    padding: 15px 0;
    margin: 0 10px 0px 0;
}


.mobile footer.site-footer section.formSec {
    padding: 40px 20px;
}

.mobile footer.site-footer section.formSec h2 {
    font-size: 18px;
}
.mobile footer.site-footer section.formSec h2 strong {
    font-size: 23px;
    margin: 2px 0;
    display: inline-block;
}

.mobile footer.site-footer .form-wrapper {
    width: 90%;
    padding: 0 5%;
    margin: 30px auto 0;
}

.mobile footer.site-footer.yes .form-wrapper .sendBtn {
    margin-top: 40px;
}

.mobile footer.site-footer .form-wrapper .MuiInput-root.MuiInput-underline {
    margin-bottom: 0;
}

.mobile footer.site-footer .form-wrapper .MuiFormControl-root {
    width: 100%;
    position: relative;
    margin: 0px 0 0 0;
}

.mobile footer.site-footer .form-wrapper .MuiFormLabel-root.MuiInputLabel-root {
    width: 100%;
}

.mobile .form-wrapper .MuiFormLabel-root.Mui-focused,
.mobile .form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
    right: -70px !important;
}

.mobile footer.site-footer .form-wrapper .selectFormGroup {
    width: 100%;
    margin: 18px 0px 30px 10px;
}
.mobile footer.site-footer .form-wrapper .selectFormGroup select {
    width: 100%;
    background-color: transparent;
}

footer.site-footer .downText {
    background: #E9E9E9;
}

footer.site-footer .downText .text {
    margin: 0 0 30px 0;
}


footer.site-footer .downText .text a {
    color: black;
    text-decoration: underline;
}



.mobile .credit {
    background:  #E9E9E9;
    text-align: center;

}
.mobile .credit img {
    width: 150px;
}

.mobile .projectView .infoProject section.plans img.lupa {
    display: none;
    width: 40px !important;
    bottom: 40px;
    margin: 0;
    /* opacity: 1;
    filter: alpha(opacity=100); */
}

.mobile .m_dansLogos {
    margin: 20px 0 0 0;
    padding: 0 10%;
}

.mobile .m_bdi {
    margin: 20px 0 20px 0;
    padding: 0 18%;
}

.mobile .projectView.urbanRenewalPage header h1 {
    position: relative;
    font-family: "fb_bold","helvetica","arial";
    font-size: 30px;
    letter-spacing: -1px;
    
}

.mobile .projectView.urbanRenewalPage header img.logoCity {
    width: 140px;
    margin: 10px auto 30px;
    display: block;
}

.mobile .projectView.urbanRenewalPage header hr {
    border-color: #adadad;
    width: 60%;
    margin: 0 auto;
}


.mobile .projectView.urbanRenewalPage header .text {
    font-size: 15px;
    font-family: "fb_regular","helvetica","arial";
}

.mobile .projectView.urbanRenewalPage .text {
    margin: 20px 0 0px 0;
    padding: 0 15%;
    text-align: center;
}


.mobile .projectView.urbanRenewalPage .text .showTextGeneral {
    height: 272px;
    overflow: hidden;
    margin: 0 0 20px 0;
}

.mobile .projectView.urbanRenewalPage .text .less .showTextGeneral {

    height: inherit;

}

.mobile .projectView.urbanRenewalPage .text .readMoreBtn {
    background: none;
    border: 1px solid grey;
    padding: 5px 15px;
    font-family: "fb_bold","helvetica","arial";
    color: black;
}

.mobile .projectView.urbanRenewalPage .text .readMoreBtn:hover,
.mobile .projectView.urbanRenewalPage .text .readMoreBtn:focus {
    color: black;
}

.mobile .urbanRenewalPage .urbanProjects {
    width: 100%;
    min-width: inherit;
}


.mobile .urbanRenewalPage .urbanProjects .urbanProject .gallery .carouselProj .carousel-control-prev span,
.mobile .urbanRenewalPage .urbanProjects .urbanProject .gallery .carouselProj .carousel-control-next span
{
    width: 15px;
    height: 42px;
}

.mobile .urbanRenewalPage .urbanProjects .urbanProject .down .flach {
    margin: -26px -28px 0 0;
}

.mobile .urbanRenewalPage .urbanProjects .urbanProject .down .flach h2 {
    font-size: 20px;
    padding: 12px 30px 14px;
}

.mobile .urbanRenewalPage .urbanProjects .urbanProject .down .text {
    
    padding: 10px 5px;
    

}

.mobile .urbanRenewalPage .urbanProjects .urbanProject .down .logos {
    width: 60%;
    margin: 0 auto 40px;
    float: none;
}


.mobile .urbanRenewalPage .urbanProjects .urbanProject .m_pic {
    margin: -1px 0 0 0;
    width: 100%;
}


.mobile .urbanRenewalPage .urbanProjects .urbanProject.no_pic {
    width: 80%;
    display: block;
    margin: 0 auto 60px;
    min-height: 0px;
}

.mobile .urbanRenewalPage .urbanProjects .urbanProject .down {
    min-height: 0px;
}

.mobile .urbanRenewalPage .urbanProjects .urbanProject.no_pic .down .text {
    padding-top: 33px;
    padding-bottom: 25px;
    float: initial;
}
 