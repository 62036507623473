/* MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU */

.body-container.accessibility .site-header {
  background: black !important;
}

.body-container.accessibility
  .site-header
  .main-menu
  nav
  .dropdown
  .dropdown-menu
  a,
.body-container.accessibility
  .site-header
  .main-menu
  nav
  .dropdown
  .dropdown-menu
  button {
  background: yellow;
  color: black !important;
  font-weight: bold;
}

.body-container.accessibility .site-header .main-menu nav a,
.body-container.accessibility
  .site-header
  .main-menu
  nav
  .dropdown
  button.dropdown-toggle {
  color: yellow !important;
}

.body-container.accessibility span.sitePhone {
  color: yellow !important;
}

.body-container.accessibility .site-header .social a img.white {
  display: inline-block !important;
}

.body-container.accessibility .site-header .social a img.black {
  display: none !important;
}

/* END MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU  MENU */

.accessibility.welcome_index .section1 {
  background: black;
}

.accessibility.welcome_index .section1 h1 {
  color: yellow;
}

.accessibility.welcome_index .section1 img {
  visibility: hidden;
}

.accessibility .HomePage .MuiTabs-flexContainer button.MuiButtonBase-root,
.accessibility .HomePage .MuiBox-root .searchCity .MuiFormLabel-root,
.accessibility .HomePage section.HomeProjects .MuiBox-root .HomeProjectCard h2 {
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility
  .HomePage
  section.HomeProjects
  .MuiBox-root
  .HomeProjectCard
  button {
  background: black;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility footer.site-footer section.formSec {
  background: black !important;
  color: yellow;
}

.accessibility
  footer.site-footer
  .form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: yellow;
}

.accessibility footer.site-footer .form-wrapper .selectFormGroup select {
  background-color: black;
  color: yellow;
}

.accessibility footer.site-footer .siteMapTable .col.sites ul li a {
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility #openAccs {
  opacity: 100;
}

.accessibility .HomePage .bulletsSec {
  background: white;
  border-bottom: 1px solid black;
}

.accessibility .AllProjectsPage nav,
.accessibility .AllProjectsPage nav .dropdown .dropdown-menu {
  background: black;
}

.accessibility .AllProjectsPage nav button,
.accessibility .AllProjectsPage nav span,
.accessibility .AllProjectsPage nav .dropdown .dropdown-toggle,
.accessibility .AllProjectsPage nav .dropdown a,
.accessibility .AllProjectsPage nav .dropdown a.active,
.accessibility .AllProjectsPage nav .dropdown a:hover,
.accessibility .AllProjectsPage nav .dropdown a:focus {
  color: yellow;
}

.accessibility .AllProjectsPage nav button.active {
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .AllProjectsPage .HomeProjectCard button,
.accessibility .AllProjectsPage .HomeProjectCard:hover button {
  background-color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
  color: yellow;
}

.accessibility .AllProjectsPage .HomeProjectCard h2 {
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .AllProjectsPage .searchCity .MuiFormLabel-root {
  color: black;
}

.accessibility .MuiChip-root {
  background: black;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .CenterPage header,
.accessibility .JobsPage header {
  background: white;
}

.accessibility .CenterPage nav a .text h2 {
  color: black;
}

.accessibility .CenterPage nav a:hover .text h2,
.accessibility .CenterPage nav a:focus .text h2 {
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .CenterPage button.fakeBtn {
  background: black !important;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .ArticlePage nav a {
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.accessibility .AboutPage .greyPass {
  background: white;
}

.accessibility .AboutPage .MuiContainer-root .tableAbout .text {
  color: black;
}

.accessibility .JobsPage .accordion .card .card-body,
.accessibility .JobsPage iframe {
  background: white;
}

.accessibility .projectView .tableProject .col.navProj {
  background: white;
  color: black;
}

.accessibility .projectView .tableProject .navProj header .text {
  color: black;
}

.accessibility
  .projectView
  .tableProject
  .infoProject
  section.plans
  .carousel-item
  .carousel-caption {
  background: black;
  color: yellow;
}

.accessibility .projectView .tableProject .infoProject section.technics {
  background: black !important;
}

.accessibility .projectView .tableProject .infoProject section.technics h2 {
  color: yellow;
}

.accessibility .projectView .tableProject .infoProject section.technics ul li {
  color: yellow;
}

.accessibility
  .projectView
  .tableProject
  .infoProject
  .gallery
  h2.textOnGallery {
  background: black !important;
  color: yellow;
}

.accessibility .ContactPage .text {
  background: white;
}

.accessibility .ShowPopUp {
  background: black;
}

.accessibility .ShowPopUp .textPopUp {
  color: yellow;
}

/* MOBILE  MOBILE  MOBILE  MOBILE  MOBILE  MOBILE  MOBILE  MOBILE  MOBILE */

.mobile.accessibility .site-header .openIcon span {
  background: yellow;
}

.mobile.accessibility .AllProjectsPage nav .dropdown .btn-primary:hover,
.mobile.accessibility .AllProjectsPage nav .dropdown .btn-primary:focus,
.mobile.accessibility .AllProjectsPage nav .dropdown .btn-primary,
.mobile.accessibility .dropdown-menu {
  background: black;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.mobile.accessibility footer.site-footer .downText,
.mobile.accessibility footer.site-footer .credit {
  background: white;
}

.mobile.accessibility .AllProjectsPage .searchCity .MuiSelect-select {
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.mobile.accessibility .AllProjectsPage.Page .searchCity .MuiFormControl-root,
.mobile.accessibility .AllProjectsPage.Page .searchCity .MuiFormLabel-root {
  color: yellow;
}

.mobile.accessibility
  .projectView
  .infoProject
  section.more
  .HomeProjectCard
  h2 {
  color: black;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.mobile.accessibility
  .projectView
  .infoProject
  section.more
  .HomeProjectCard
  button {
  background: black !important;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.mobile.accessibility .projectView .infoProject section.m_technics {
  background: black !important;
}

.mobile.accessibility .projectView .infoProject section.m_technics h2,
.mobile.accessibility .projectView .infoProject section.m_technics h3,
.mobile.accessibility .projectView .infoProject section.m_technics ul li {
  color: yellow;
}

.mobile.accesibility
  footer.site-footer
  .form-wrapper
  button.sendBtn
  .mobile.accesibility
  footer.site-footer
  .form-wrapper
  button.sendBtn:hover {
  color: yellow;
}

.mobile.accessibility .CenterPage nav a .text .fakeBtn,
.mobile.accessibility .CenterPage nav a:hover .text .fakeBtn,
.mobile.accessibility .CenterPage nav a:focus .text .fakeBtn {
  background: black;
  color: yellow;
  font-family: 'fb_regular', 'helvetica', 'arial';
}

.mobile.accessibility
  .AboutPage
  .MuiContainer-root
  .tableAbout
  .text.textBlack
  h2 {
  color: yellow;
}

.mobile.accessibility .OpenCalc img.regular {
  display: none;
}
.mobile.accessibility .OpenCalc img.acc {
  display: block;
}

.mobile.accessibility .AboutPage .aboutUp {
  background: white;
}

.mobile.accessibility .AboutPage .aboutUp .textConont h2 {
  color: black;
}

.accessibility .AboutPage .aboutUp {
  background: white !important;
}

.accessibility .AboutPage .aboutUp .textConont h2 {
  color: black;
}

.mobile.accessibility .AboutPage .geresh {
  display: none;
}

.accessibility .AboutPage .texts .text {
  color: black;
}
